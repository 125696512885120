import React from "react";

export default function MethodCard(props) {
  return (
    <>
      <div className={(props.index === 0 ? "xl:pl-32 pl-16" : "pl-4")+" xl:block hidden"}>
        <div className="bg-blueGray-50 rounded-3xl px-14 justify-between flex flex-row items-center" style={{width: "40vw", aspectRatio: "2/1", minWidth: 666}}>
          <div className="pb-11">
            <p className="text-blueGray-800 text-5xl font-sen font-extrabold">{props.title}<span className="text-cyan-500">.</span></p>
            <p className="text-blueGray-800 mt-4 text-xl font-inter font-bold">All models. <span className="text-blueGray-300 font-normal">Take your pick.</span></p>
          </div>
          <div 
            className="rounded-full flex flex-col items-center h-60 w-60 justify-center items-center"
            style={{
              background: 'rgb(8,145,178)',
              background: 'linear-gradient(257deg, rgba(8,145,178,1) 0%, rgba(6,182,212,1) 81%)'
            }}
          >
            {props.icon}
          </div>
        </div>
      </div>
      <div className={(props.index === 0 ? "xl:pl-32 pl-16" : "pl-4")+" md:block xl:hidden hidden"}>
        <div className="bg-blueGray-50 rounded-3xl px-11 justify-between flex flex-row items-center" style={{width: "40vw", aspectRatio: "2/1", minWidth: 600}}>
          <div className="pb-11">
            <p className="text-blueGray-800 text-5xl font-sen font-extrabold">{props.title}<span className="text-cyan-500">.</span></p>
            <p className="text-blueGray-800 mt-4 text-xl font-inter font-bold">All models. <span className="text-blueGray-300 font-normal">Take your pick.</span></p>
          </div>
          <div 
            className="rounded-full flex flex-col items-center h-48 w-48 justify-center items-center"
            style={{
              background: 'rgb(8,145,178)',
              background: 'linear-gradient(257deg, rgba(8,145,178,1) 0%, rgba(6,182,212,1) 81%)'
            }}
          >
            {props.icon}
          </div>
        </div>
      </div>
      <div className={(props.index === 0 ? "xl:pl-32 pl-16" : "pl-4")+" sm:block xl:hidden md:hidden hidden"}>
        <div className="bg-blueGray-50 rounded-3xl px-9 justify-between flex flex-row items-center" style={{width: "40vw", aspectRatio: "2/1", minWidth: 550}}>
          <div className="pb-11">
            <p className="text-blueGray-800 text-5xl font-sen font-extrabold">{props.title}<span className="text-cyan-500">.</span></p>
            <p className="text-blueGray-800 mt-4 text-xl font-inter font-bold">All models. <span className="text-blueGray-300 font-normal">Take your pick.</span></p>
          </div>
          <div 
            className="rounded-full flex flex-col items-center h-44 w-44 justify-center items-center"
            style={{
              background: 'rgb(8,145,178)',
              background: 'linear-gradient(257deg, rgba(8,145,178,1) 0%, rgba(6,182,212,1) 81%)'
            }}
          >
            {props.icon}
          </div>
        </div>
      </div>
      <div className={(props.index === 0 ? "xl:pl-32 pl-4" : "pl-3")+" block sm:hidden"}>
        <div className="bg-blueGray-50 rounded-3xl p-6 px-4 justify-between flex flex-row items-center" style={{ aspectRatio: "2/0.9", width: 370}}>
          <div >
            <p className="text-blueGray-800 text-4xl font-sen font-extrabold">{props.title}<span className="text-cyan-500">.</span></p>
            <p className="text-blueGray-800 text-xl font-inter font-bold">All models. <span className="text-blueGray-300 font-normal">Take your pick.</span></p>
          </div>
          <div 
            className="rounded-full flex flex-col items-center h-20 w-20 justify-center items-center"
            style={{
              background: 'rgb(8,145,178)',
              background: 'linear-gradient(257deg, rgba(8,145,178,1) 0%, rgba(6,182,212,1) 81%)'
            }}
          >
            {props.icon}
          </div>
        </div>
      </div>
    </>
  );
}
