export const methods = [
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="xl:h-44 sm:h-32 xl:w-44 sm:w-32 h-14 w-14" viewBox="0 0 20 20" fill="#67E8F9">
        <path fill-rule="evenodd" d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z" clip-rule="evenodd" />
        <path fill-rule="evenodd" d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z" clip-rule="evenodd" />
        <path fill-rule="evenodd" d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z" clip-rule="evenodd" />
      </svg>
    ),
    title: "Biometrics"
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="xl:h-44 sm:h-32 xl:w-44 sm:w-32 h-14 w-14" viewBox="0 0 20 20" fill="#67E8F9">
        <path fill-rule="evenodd" d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z" clip-rule="evenodd" />
      </svg>
    ),
    title: "RFID"
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="xl:h-44 sm:h-32 xl:w-44 sm:w-32 h-14 w-14" viewBox="0 0 20 20" fill="#67E8F9">
        <path fill-rule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clip-rule="evenodd" />
      </svg>
    ),
    title: "Web App"
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="xl:h-44 sm:h-32 xl:w-44 sm:w-32 h-14 w-14" viewBox="0 0 20 20" fill="#67E8F9">
        <path fill-rule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    ),
    title: "Mobile App"
  }
]

export const features = [
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="sm:h-12 h-8 sm:w-12 w-8" viewBox="0 0 20 20" fill="#0891B2">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
      </svg> 
    ),
    title: "Daily time record",
    description: (<>Streamline time tracking and attendance management effortlessly.</>),
    sub: (<>Keep accurate records of your<br/>employees' work hours.</>),
    img: "dtr.png",
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="sm:h-12 h-8 sm:w-12 w-8" viewBox="0 0 20 20" fill="#0891B2">
        <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
      </svg>
    ),
    title: "Payroll",
    description: (<>Simplify your payroll process with our intuitive system.</>),
    sub: (<>Calculate salaries, deductions,<br/>and bonuses with ease.</>),
    img: "payroll.png",
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="sm:h-12 h-8 sm:w-12 w-8" viewBox="0 0 20 20" fill="#0891B2">
        <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
      </svg>
    ),
    title: "HR Management",
    description: (<>Centralize your human resources tasks with our comprehensive HR management tools.</>),
    sub: (<>Manage employee information,<br/>benefits, and more in one place.</>),
    img: "hrmanagement.png",
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="sm:h-12 h-8 sm:w-12 w-8" viewBox="0 0 20 20" fill="#0891B2">
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>
    ),
    title: "Team Monitoring",
    description: (<>Monitor your team's activities and productivity effectively.</>),
    sub: (<>Track progress on tasks, identify<br/>bottlenecks, and optimize team performance.</>),
    img: "team_monitor.png",
  }
]