import React from "react";

export default function FeatureCard(props) {
  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 bg-blueGray-100 w-full xl:px-32 sm:px-16 px-4 sm:pt-16 pt-10 lg:pb-20 pb-10">
      <div onClick={() => props.headerRef?.current?.scrollIntoView({block: "start", behavior: "smooth"})} className="cursor-pointer sm:hidden lg:hidden block w-full flex flex-row justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" style={{marginRight: -10}} viewBox="0 0 20 20" fill="#0891B2">
          <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
      </div>
      <div className="flex flex-col justify-end">
        <div className="flex flex-row justify-between">
          <img className="w-48" src={require("assets/img/logo_dark.png").default} />
          <div onClick={() => props.headerRef?.current?.scrollIntoView({block: "start", behavior: "smooth"})} className="cursor-pointer sm:block lg:hidden hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" style={{marginRight: -10}} viewBox="0 0 20 20" fill="#0891B2">
              <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col mt-12">
          <p className="text-2xl font-inter font-normal text-blueGray-500 hover:text-cyan-600 cursor-pointer mr-8">Privacy Policy</p>
          <p className="text-2xl font-inter font-normal text-blueGray-500 hover:text-cyan-600 cursor-pointer">Terms & Conditions</p>
        </div>
      </div>
      <div className="flex flex-col justify-between lg:items-end items-start">
        <div onClick={() => props.headerRef?.current?.scrollIntoView({block: "start", behavior: "smooth"})} className="cursor-pointer lg:block hidden">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" style={{marginRight: -10}} viewBox="0 0 20 20" fill="#0891B2">
            <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
        </div>
        <p className="text-2xl font-inter font-normal text-blueGray-500 transition duration-300 ease-in-out hover:text-cyan-900">
          Powered by<span className="font-extrabold text-cyan-600"> Boohat </span> <span className="sm:inline hidden">&nbsp;&nbsp;•&nbsp;&nbsp;</span> <span className="sm:inline block">Tawo© 2024</span>
        </p>
      </div>
    </div>
  );
}