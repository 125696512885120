import { useEffect, useState, useRef } from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MainNavbar from "components/Navbars/MainNavbar.js";
import { Provider }         from "react-redux";
import { PersistGate }      from "redux-persist/integration/react";
import { store, persistor } from "../redux/store.js";
import FooterMain from "components/Footers/FooterMain.js";
import {useScrollPosition} from "../hooks/useScrollPosition";
import FeatureCard from "../components/FeatureCard.js";
import MethodCard from "../components/MethodCard.js";
import { features, methods } from "./data.js";
import Modal from "../components/Modal.js";

const settings = {
  className: "slider variable-width",
  dots: true,
  infinite: false,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  appendDots: dots => (
    <div
      className="test"
      style={{
        display: 'flex',
        alignItems: 'center',
        height: 55
      }}
    >
      <ul> {dots} </ul>
    </div>
  ),
  customPaging: i => ( <div className="slick-dot-width"></div> ),
};

export default function Admin() {

  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollPosition = useScrollPosition();
  const featureRef = useRef(null);
  const methodRef = useRef(null);
  const contactRef = useRef(null);
  const aboutRef = useRef(null);
  const headerRef = useRef(null);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);

  const openLearnMore = (data) => {
    modalRef.current?.setData(data.title);
    modalRef.current?.show();
  }

  return (
    <Provider store={store}>
      <PersistGate loading={false} persistor={persistor}>
        <>
          <div className="relative min-h-screen flex flex-col overflow-hidden">
            <MainNavbar 
              methodRef={methodRef}
              featureRef={featureRef}
              aboutRef={aboutRef}
              contactRef={contactRef}
              isTop={scrollPosition < 200}
            />
            <div 
              ref={headerRef}
              className="min-h-screen flex-col justify-center lg:flex hidden" 
              style={{
                background: 'rgb(8,145,178)',
                background: 'linear-gradient(180deg, rgba(8,145,178,1) 0%, rgba(174,219,230,1) 40%, rgba(255,255,255,1) 100%)'
              }}
            >
              <div className="w-full xl:px-32 sm:px-16 px-4 mx-auto items-center grid lg:grid-cols-2 grid-cols-1 gap-4">
                <div className="h-full flex flex-col justify-center">
                  <p className="text-cyan-900 text-6xl font-sen font-extrabold">
                    Efficiency in Every Tick<span className="text-cyan-500">!</span>
                  </p>
                  <p className="text-blueGray-600 text-3xl mt-6 font-inter font-normal">
                    Empower your Workforce, elevate Productivity, and simplify Processes with our HRIS solutions
                  </p>
                </div>
                <div className="h-full flex flex-col justify-center">
                  <img className="absolute w-1/2 ml-8 mt-48" src={require("assets/img/hero-banner.png").default} />
                </div>
              </div>
            </div>
            <div 
              style={{
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '75vw',
                backgroundImage: "url('"+require("assets/img/hero-banner.png").default+"')",
                backgroundPosition: "right bottom",
                width: "calc(100vw + 3.5rem)"
              }}
              className="min-h-screen flex flex-col justify-center lg:hidden pr-14" 
            >
              <div 
                className="min-h-screen flex flex-col justify-center" 
                style={{
                  background: 'rgb(8,145,178)',
                  background: 'linear-gradient(180deg, rgba(8,145,178,0.8) 0%, rgba(174,219,230,0.8) 40%, rgba(255,255,255,1) 100%)'
                }}
              >
                <div className="w-full xl:px-32 sm:px-16 px-4 mx-auto items-center grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div className="h-full flex flex-col justify-center">
                    <p className="text-cyan-900 text-6xl font-sen font-extrabold">
                      Efficiency in Every Tick<span className="text-cyan-500">!</span>
                    </p>
                    <p className="text-blueGray-600 text-3xl mt-6 font-inter font-normal">
                      Empower your Workforce, elevate Productivity, and simplify Processes with our HRIS solutions
                    </p>

                  </div>
                </div>
              </div>
            </div>
            <div className="w-full xl:px-32 sm:px-16 px-4 mx-auto mt-16 scroll-margin-long" ref={featureRef}>
              <p className="text-cyan-600 sm:text-6xl text-5xl font-sen font-extrabold">Features</p>
              <p className="text-blueGray-700 text-2xl font-inter font-normal mt-5">
                Our platform offers comprehensive <span className="font-inter font-extrabold">suite of tools</span> designed to streamline your business operations.
              </p>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-16">
                {features.map((value, index) => {
                  return <FeatureCard key={index} {...value} learnMore={openLearnMore} />
                })}
              </div>
            </div>
            <div className="w-full xl:px-32 sm:px-16 px-4 mt-4">
              <div className="w-full mx-auto">
                <div 
                  className="rounded-3xl rounded-bl-3xl flex flex-col items-center xl:p-28 lg:p-20 sm:p-14 p-7 xl:py-28 lg:py-20 py-14"
                  style={{
                    background: 'rgb(8,145,178)',
                    background: 'linear-gradient(257deg, rgba(8,145,178,1) 0%, rgba(6,182,212,1) 81%)'
                  }}
                >
                  <div className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 px-2" viewBox="0 0 20 20" fill="#22D3EE">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                    </svg> 
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 px-2" viewBox="0 0 20 20" fill="#22D3EE">
                      <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 px-2" viewBox="0 0 20 20" fill="#22D3EE">
                      <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
                      <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 px-2" viewBox="0 0 20 20" fill="#22D3EE">
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                  </div>
                  <p className="text-cyan-100 md:text-3xl text-2xl font-inter font-normal mt-10 text-center">
                    Elevate your business with our comprehensive solution, seamlessly integrating daily time record, payroll management, HR administration, and team monitoring for unparalleled empowerment.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full mx-auto mt-16 scroll-margin" ref={methodRef}>
              <div className="w-full xl:px-32 sm:px-16 px-4 mx-auto">
                <div className="h-16 border-t border-blueGray-400"></div>
                <p className="text-cyan-600 sm:text-6xl text-5xl font-sen font-extrabold">Methods</p>
                <p className="text-blueGray-700 text-2xl font-inter font-normal mt-5">
                  Our platform offers comprehensive <span className="font-inter font-extrabold">suite of tools</span> designed to streamline your business operations.
                </p>
              </div>
              <div className="slider-container mt-14">
                <Slider {...settings} ref={sliderRef} beforeChange={(currentSlide: number, nextSlide: number) => setCurrentIndex(nextSlide)}>
                  {methods.map((value, index) => {
                    return (
                      <MethodCard key={index} index={index} { ...value } />
                    )
                  })}
                </Slider>
              </div>
              <div className="w-full xl:px-32 sm:px-16 px-4 md:flex hidden flex-row justify-end" style={{height: 55, marginTop: -30, marginRight: -10}}>
                <div className="flex flex-row">
                  <div className={`${currentIndex === 0 ? "cursor-not-allowed" : "cursor-pointer"} z-10`} onClick={() => sliderRef.current?.slickPrev()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" viewBox="0 0 20 20" fill={currentIndex === 0 ? "#CBD5E1" : "#0891B2"}>
                      <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div className={`${currentIndex === (methods.length - 1) ? "cursor-not-allowed" : "cursor-pointer"} z-10`} onClick={() => sliderRef.current?.slickNext()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" viewBox="0 0 20 20" fill={currentIndex === (methods.length - 1) ? "#CBD5E1" : "#0891B2"}>>
                      <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div 
              style={{
                background: 'rgb(8,145,178)',
                background: 'linear-gradient(257deg, rgba(8,145,178,1) 0%, rgba(6,182,212,1) 81%)'
              }}
              className="w-full xl:px-32 sm:px-16 px-4 mt-24 grid lg:grid-cols-2 grid-cols-1 gap-4 lg:py-16 py-12 scroll-margin" ref={aboutRef}
            >
              <div className="lg:hidden flex flex-row justify-center">
                <img className="w-96" src={require("assets/img/atom.png").default}/>
              </div>
              <div className="flex flex-col justify-center">
                <div className="text-white font-sen font-extrabold sm:text-6xl text-5xl">
                  Your partner throughout the employee lifecycle.
                </div>
                <div className="text-white font-inter font-normal text-3xl mt-6">
                  From hire to retire, best-in-class solutions for every step of your employees’ journey.
                </div>
              </div>
              <div className="lg:flex hidden flex-row justify-center py-16">
                <img className="2xl:w-7/12 w-96" src={require("assets/img/atom.png").default}/>
              </div>
            </div>
            <div 
              className="w-full xl:px-32 sm:px-16 px-4 grid lg:grid-cols-2 grid-cols-1 gap-4 scroll-margin" ref={contactRef}
            >
              <div className="flex flex-col justify-center lg:pt-0 pt-8">
                <div className="text-blueGray-700 font-sen font-extrabold md:text-8xl text-7xl">
                  Get in Touch with Us Today!
                </div>
                <div className="bg-cyan-600 mt-9 rounded-full" style={{width: 122, height: 8}}></div>
                <div className="text-blueGray-400 font-inter font-normal text-2xl mt-11">
                  Or Send us and email at <span className="font-bold text-blueGray-600">info@tawo.ph</span> <span className="font-bold text-cyan-700">/</span> <span className="font-bold text-blueGray-600">099999XXXX</span>
                </div>
              </div>
              <div className="flex flex-row justify-center pb-24 lg:pt-32 pt-4 w-full">
                <div className="border border-blueGray-400 w-full rounded-3xl 2xl:p-16 xl:p-12 lg:p-9 2xl:pb-20 lg:pb-16 sm:p-16 sm:pb-20 p-6 pb-6">
                  <div className="flex flex-col">
                    <label className="font-inter font-bold text-blueGray-600 text-xl">Name <span className="font-normal">(Optional)</span></label>
                    <input 
                      className="transition duration-500 ease-in-out border border-blueGray-300 bg-blueGray-50 h-12 rounded-md mt-4 px-6 placeholder-blueGray-400 focus:border-2 focus:border-cyan-600 focus:outline-none focus:ring-0"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="flex flex-col mt-7">
                    <label className="font-inter font-bold text-blueGray-600 text-xl">Email</label>
                    <input 
                      className="transition duration-500 ease-in-out border border-blueGray-300 bg-blueGray-50 h-12 rounded-md mt-4 px-6 placeholder-blueGray-400 focus:border-2 focus:border-cyan-600 focus:outline-none focus:ring-0"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="flex flex-col mt-7">
                    <label className="font-inter font-bold text-blueGray-600 text-xl">Message</label>
                    <textarea 
                      className="transition duration-500 ease-in-out border border-blueGray-300 bg-blueGray-50 rounded-md mt-4 px-6 placeholder-blueGray-400 focus:border-2 focus:border-cyan-600 focus:outline-none focus:ring-0 resize-none"
                      placeholder="Write a message"
                      rows="5"
                    />
                  </div>
                  <div className="mt-7 w-full flex flex-row justify-end">
                    <button className="rounded-md bg-cyan-600 hover:bg-cyan-900 transition duration-300 ease-in-out text-xl text-white font-inter font-normal px-10 py-3.5 md:w-auto w-full">Send Message</button>
                  </div>
                </div>
              </div>
            </div>
            <FooterMain headerRef={headerRef}/>
          </div>
        </>
        <Modal ref={modalRef}/>
      </PersistGate>
    </Provider>
  );
}
