import React from "react";
import { Link } from "react-router-dom";


export default function Navbar({isTop, featureRef, methodRef, aboutRef, contactRef}) {


  return (
    <div className={`${!isTop ? "bg-white shadow" : ""} left-0 w-full z-20 fixed top-0 flex flex-col top-0 transition duration-500 ease-in-out`}>
      {/* Navbar */}
      <nav>
        <div className={`${isTop ? 'sm:h-48 h-20' : 'h-20'} w-full xl:px-32 sm:px-16 px-4 mx-auto items-center flex flex-wrap transition duration-500 ease-in-out`}>
          {/* User */}
          <div className="h-20 flex-row list-none items-center flex w-full justify-between">
            <div className="text-3xl hover:text-main-700 text-main-700 font-bold cursor-pointer h-full flex flex-col justify-center">
              <img className={`transform ${!isTop ? "sm:scale-75 scale-100" : "scale-100"} origin-left sm:h-14 h-8 transition-transform duration-1000 ease-in-out`} src={require(`assets/img/logo_${isTop ? 'light' : 'colored'}.png`).default} />
            </div>
            <div className="lg:flex hidden flex flex-row py-3" style={{fontFamily: 'Inter', fontWeight: '400'}} >
              <div onClick={() => featureRef?.current?.scrollIntoView({block: "start", behavior: "smooth"})} className={`transition duration-300 ease-in-out hover:text-cyan-${isTop ? "900" : "600"} ${isTop ? 'text-cyan-50' : 'text-blueGray-700'} cursor-pointer xl:px-7 px-5 py-4 text-2xl font-inter`}>Features</div>
              <div onClick={() => methodRef?.current?.scrollIntoView({block: "start", behavior: "smooth"})} className={`transition duration-300 ease-in-out hover:text-cyan-${isTop ? "900" : "600"} ${isTop ? 'text-cyan-50' : 'text-blueGray-700'} cursor-pointer xl:px-7 px-5 py-4 text-2xl font-inter`}>Methods</div>
              <div onClick={() => aboutRef?.current?.scrollIntoView({block: "start", behavior: "smooth"})} className={`transition duration-300 ease-in-out hover:text-cyan-${isTop ? "900" : "600"} ${isTop ? 'text-cyan-50' : 'text-blueGray-700'} cursor-pointer xl:px-7 px-5 py-4 text-2xl font-inter`}>About</div>
              <div onClick={() => contactRef?.current?.scrollIntoView({block: "start", behavior: "smooth"})} className={`transition duration-300 ease-in-out hover:text-cyan-900 ${isTop ? 'text-cyan-50' : 'text-cyan-600'} cursor-pointer xl:px-7 px-5 py-4 pr-0 text-2xl font-inter font-bold`}>Contact us now</div>
            </div>
            <div className="lg:hidden block">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-16 h-14 sm:w-16 h-14" viewBox="0 0 20 20" fill={isTop ? "#ECFEFF" : "#334155"}>
                  <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* End Navbar */}
    </div>
  );
}

