import { createSlice }  from "@reduxjs/toolkit";

const initialState = { 
   id: null,
   step1: {
      ads_name: null,
      tagline: null,
      about_me: null,
      offer_list: null,
      donations: null
   },
   step2: {
      ad_location: null,
      service_as: null,
      service_provided: [],
      client_gender: [],
      ad_type: null,
      start_date: 0,
      days_selected: null,
      visiting_text: null
   },
   step3: {
      featured_photo: null,
      ads_photo: []
   },
   step4: {
      unaltered_photo: []
   },
   step5: {
      premiere_service: [],
      total: 0
   },
   step6: {
      is_vip: null,
      ads_special_category: []
   },
   step7: {

   },
   is_next_loading: false,
   estimated_cost: 0,
   current_step: 0
}

export const create_ads_slice = createSlice({
   initialState,
   name: "create_ads",
   reducers: {
      resetForm: (state) => state = initialState, 
      setForm: (state, action) => {
         if(action.payload.id !== undefined){
            state.id = action.payload.id;
         }
         state.is_next_loading = true;
         state.current_step = action.payload.step;
         state[`step${action.payload.step.toString()}`] = action.payload.data;

      },
      setIsNextLoading: (state, action) => {
         state.is_next_loading = action.payload;
      },
      setEstimatedCost: (state, action) => {
         state.estimated_cost = action.payload;
      }
   },
});

export const { 
   resetForm,
   setForm,
   setIsNextLoading,
   setEstimatedCost
} = create_ads_slice.actions;

export const selectUser    = (state: object) => state.create_ads; 
export const selectStep1   = (state: object) => state.create_ads.step1;
export const selectStep2   = (state: object) => state.create_ads.step2;
export const selectStep3   = (state: object) => state.create_ads.step3;
export const selectStep4   = (state: object) => state.create_ads.step4;
export const selectStep5   = (state: object) => state.create_ads.step5;
export const selectStep6   = (state: object) => state.create_ads.step6;
export const selectStep7   = (state: object) => state.create_ads.step7;
export const selectId      = (state: object) => state.create_ads.id; 
export const selectIsNextLoading = (state: object) => state.create_ads.is_next_loading; 
export const selectCurrentStep = (state: object) => state.create_ads.current_step; 
export const selectEstimatedCost = (state: object) => state.create_ads.estimated_cost; 

export default create_ads_slice.reducer;