import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";


import Landing from "layouts/Main.js";

ReactDOM.render(
  <HashRouter basename="/">
    <Switch>
      {/* add routes with layouts */}
      <Route path="/" component={Landing} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
