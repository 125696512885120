import { createSlice }  from "@reduxjs/toolkit";

const initialState = { 
   total_amount: 0,
   total_quantity: 0,
   cart_items: [],
   is_next_loading: false,
}

export const cart_slice = createSlice({
   initialState,
   name: "cart",
   reducers: {
      resetForm: (state) => state = initialState, 
      setCart: (state, action) => {
         if(action.payload.total_amount !== undefined){
            state.total_amount = action.payload.total_amount;
         }
         if(action.payload.total_quantity !== undefined){
            state.total_quantity = action.payload.total_quantity;
         }
         if(action.payload.cart_items !== undefined){
            state.cart_items = action.payload.cart_items;
         }
      }
   },
});

export const { 
   resetForm,
   setCart,
} = cart_slice.actions;

export const selectCart          = (state: object) => state.cart; 
export const selectTotalAmount   = (state: object) => state.cart.total_amount;
export const selectTotalQuantity = (state: object) => state.cart.total_quantity;
export const selectCartItems     = (state: object) => state.cart.cart_items;

export default cart_slice.reducer;