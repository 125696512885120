import { createSlice }  from "@reduxjs/toolkit";

const initialState = { 
   is_next_loading: false,
}

export const global_slice = createSlice({
   initialState,
   name: "global",
   reducers: {
      setIsNextLoading: (state, action) => {
         state.is_next_loading = action.payload;
      },
   },
});

export const { 
   setIsNextLoading
} = global_slice.actions;

export const selectIsNextLoading = (state: object) => state.global.is_next_loading; 

export default global_slice.reducer;