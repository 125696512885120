import React from "react";

export default function FeatureCard(props) {
  return (
    <>
      <div className="lg:flex md:hidden hidden border hover:border-blueGray-400 border-white bg-blueGray-50 rounded-3xl xl:pl-11 pl-7 xl:pt-14 pt-7 flex-col justify-between overflow-hidden" style={{aspectRatio: "1/1"}}>
        <div>
          <div className="flex flex-row">
            {props.icon}
            <p className="text-blueGray-800 text-5xl font-sen font-extrabold ml-4">
              {props.title}<span className="text-cyan-500">.</span>
            </p>
          </div>
          <p className="text-blueGray-700 text-xl mt-6 font-inter font-normal">
            {props.description}
          </p>
        </div>
        <div className="grid grid-cols-2 h-4/6 overflow-hidden">
          <div className="flex flex-col justify-end pb-14">
            <p className="text-blueGray-400 text-base mt-6 font-inter font-normal">
              {props.sub}
            </p>
            <p className="text-cyan-600 text-xl mt-6 font-inter font-normal cursor-pointer" onClick={() => props.learnMore(props)}>
              Learn More
            </p>
          </div>
          <div className="bg-gradient-to-tr from-blueGray-200 to-blueGray-300 rounded-full flex flex-col justify-center ml-9" style={{height: "125%", aspectRatio: "1/1", marginBottom: -50}}>
            <img className="w-7/12 mb-16" src={require("assets/img/"+props.img).default} style={{marginLeft: -35}}/>
          </div>
        </div>
      </div>
      <div className="md:flex lg:hidden hidden border hover:border-blueGray-400 border-white bg-blueGray-50 rounded-3xl pl-7 pt-7 flex flex-col justify-between overflow-hidden" style={{height: "30rem"}}>
        <div>
          <div className="flex flex-row items-center">
            {props.icon}
            <p className="text-blueGray-800 text-5xl font-sen font-extrabold ml-4">
              {props.title}<span className="text-cyan-500">.</span>
            </p>
          </div>
          <p className="text-blueGray-700 text-xl mt-6 font-inter font-normal">
            {props.description}
          </p>
        </div>
        <div className="grid grid-cols-2 h-4/6 overflow-hidden">
          <div className="flex flex-col justify-center">
            <p className="text-blueGray-400 text-base mt-6 font-inter font-normal">
              {props.sub}
            </p>
            <p className="text-cyan-600 text-xl mt-6 font-inter font-normal cursor-pointer" onClick={() => props.learnMore(props)}>
              Learn More
            </p>
          </div>
          <div className="bg-gradient-to-tr from-blueGray-200 to-blueGray-300 rounded-full flex flex-col justify-center ml-9" style={{height: "125%", aspectRatio: "1/1", marginBottom: -50}}>
            <img className="w-7/12 mb-16" src={require("assets/img/"+props.img).default} style={{marginLeft: -35}}/>
          </div>
        </div>
      </div>
      <div className="sm:flex lg:hidden md:hidden hidden border hover:border-blueGray-400 border-white bg-blueGray-50 rounded-3xl pl-7 pt-7 flex flex-col justify-between overflow-hidden" style={{aspectRatio: "1/1"}}>
        <div>
          <div className="flex flex-row items-center">
            {props.icon}
            <p className="text-blueGray-800 text-5xl font-sen font-extrabold ml-2">
              {props.title}<span className="text-cyan-500">.</span>
            </p>
          </div>
          <p className="text-blueGray-700 text-xl mt-6 font-inter font-normal">
            {props.description}
          </p>
        </div>
        <div className="grid grid-cols-2 h-4/6 overflow-hidden">
          <div className="flex flex-col justify-end pb-14">
            <p className="text-blueGray-400 text-base mt-6 font-inter font-normal">
              {props.sub}
            </p>
            <p className="text-cyan-600 text-xl mt-6 font-inter font-normal cursor-pointer" onClick={() => props.learnMore(props)}>
              Learn More
            </p>
          </div>
          <div className="bg-gradient-to-tr from-blueGray-200 to-blueGray-300 rounded-full flex flex-col justify-center ml-9" style={{height: "125%", aspectRatio: "1/1", marginBottom: -50}}>
            <img className="w-7/12 mb-16" src={require("assets/img/"+props.img).default} style={{marginLeft: -35}}/>
          </div>
        </div>
      </div>
      <div className="sm:hidden lg:hidden md:hidden flex border hover:border-blueGray-400 border-white bg-blueGray-50 rounded-3xl pl-7 pt-7 flex flex-col justify-between overflow-hidden" style={{height: "30rem"}}>
        <div>
          <div className="flex flex-row items-center">
            {props.icon}
            <p className="text-blueGray-800 text-3xl font-sen font-extrabold ml-4">
              {props.title}<span className="text-cyan-500">.</span>
            </p>
          </div>
          <p className="text-blueGray-700 text-xl mt-6 font-inter font-normal">
            {props.description}
          </p>
        </div>
        <div className="grid grid-cols-2 h-4/6 overflow-hidden">
          <div className="flex flex-col justify-end pb-14">
            <p className="text-blueGray-400 text-base mt-6 font-inter font-normal">
              {props.sub}
            </p>
            <p className="text-cyan-600 text-xl mt-6 font-inter font-normal cursor-pointer" onClick={() => props.learnMore(props)}>
              Learn More
            </p>
          </div>
          <div className="bg-gradient-to-tr from-blueGray-200 to-blueGray-300 rounded-full flex flex-col justify-center ml-9" style={{height: "125%", aspectRatio: "1/1", marginBottom: -50}}>
            <img className="w-7/12 mb-16" src={require("assets/img/"+props.img).default} style={{marginLeft: -35}}/>
          </div>
        </div>
      </div>
    </>
  );
}
