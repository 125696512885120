import { 
   configureStore 
}                       from "@reduxjs/toolkit";
import { 
   combineReducers 
}                       from "redux";
import { 
   persistStore, 
   persistReducer, 
   FLUSH, 
   REHYDRATE, 
   PAUSE, 
   PERSIST, 
   PURGE, 
   REGISTER
}                       from "redux-persist"; 
import storage          from 'redux-persist/lib/storage'
import create_ads       from "./slice/create_ads_slice.js"; 
import cart             from "./slice/cart_slice.js"; 
import global           from "./slice/global_slice.js"; 


const persistConfig = {
   key: "root",
   version: 1,
   storage,
};

const reducer = combineReducers({ 
   create_ads,
   cart,
   global
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) =>
   getDefaultMiddleware({
     serializableCheck: {
       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
     },
     // immutableCheck: false,
     // serializableCheck: false
   }),
});

export let persistor = persistStore(store)