import React from "react";
import { features } from "../layouts/data.js";

export default React.forwardRef(function Modal(props, ref) {
  const [showModal, setShowModal] = React.useState(false);
  const [highlighted, setHighlighted] = React.useState(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useImperativeHandle(ref, () => {
    return {
      show: () => open(),
      hide: () => close(),
      toggle: () => toggleModal(),
      setData: (data) => {
        setHighlighted(data);
        setCurrentIndex(features.findIndex(val => val.title === data));
      }
    };
  }, []);

  const open = () => setShowModal(true);
  const close = () => setShowModal(false);
  const toggleModal = () => setShowModal(!showModal);
  const back = () => {
    if(currentIndex === 0){
      return;
    }
    setHighlighted(features[currentIndex - 1].title);
    setCurrentIndex(currentIndex - 1);
  }
  const forward = () => {
    if(currentIndex === features.length - 1){
      return;
    }
    setHighlighted(features[currentIndex + 1].title);
    setCurrentIndex(currentIndex + 1);
  }

  const data = features.find(val => val.title === highlighted) || {};

  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 max-w-6xl mx-3 sm:h-auto h-4/6">
              {/*content*/}
              <div className="border-0 rounded-xl shadow-lg h-full relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-row justify-between sm:pt-11 pt-5 sm:px-10 px-6 rounded-xl">
                  <div className="flex flex-row md:items-center">
                    <div style={{marginLeft: -6}}>
                      {data.icon}
                    </div>
                    <p className="text-blueGray-800 sm:text-5xl text-3xl font-sen font-extrabold sm:ml-4 ml-2">
                      {data.title}<span className="text-cyan-500">.</span>
                    </p>
                  </div>
                  <div
                    style={{marginTop: -20}}
                    className="cursor-pointer sm:pt-0 pt-4"
                    onClick={() => close()}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="#4A5568">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                
                <div className="relative sm:px-10 px-6 flex-auto mt-4">
                  <div className="grid md:grid-cols-11 grid-cols-1 gap-4">
                    <div 
                      style={{
                        aspectRatio: "2/1.3",
                        background: 'rgb(8,145,178)',
                        background: 'linear-gradient(257deg, rgba(8,145,178,1) 0%, rgba(6,182,212,1) 81%)'
                      }}
                      className="rounded-lg md:hidden block"
                    ></div>
                    <div className="flex flex-col justify-center md:col-span-5 pr-12">
                      <p className="text-blueGray-700 text-xl">
                        {data.description}
                      </p>
                      <p className="text-blueGray-400 text-xl mt-3.5">
                        {data.sub}
                      </p>
                    </div>
                    <div 
                      style={{
                        aspectRatio: "2/1.3",
                        background: 'rgb(8,145,178)',
                        background: 'linear-gradient(257deg, rgba(8,145,178,1) 0%, rgba(6,182,212,1) 81%)'
                      }}
                      className="rounded-lg col-span-6 md:block hidden"
                    ></div>
                  </div>
                  <div className="w-full flex flex-row justify-between sm:pb-14 pb-9 pt-4">
                    
                    <div className="flex flex-row items-center">
                      {features.map((value, index) => {

                        return (
                          <div 
                            className="cursor-pointer py-3 mr-2.5" 
                            key={index}
                            onClick={() => {
                              setHighlighted(value.title);
                              setCurrentIndex(features.findIndex(val => val.title === value.title));
                            }}
                          >
                            <div className={`h-2 md:w-28 sm:w-16 w-0 rounded-full bg-cyan-${value.title === data.title ? `600` : `50`}`}></div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="flex flex-row" style={{marginRight: -10}}>
                      <div className={`${currentIndex === 0 ? "cursor-not-allowed" : "cursor-pointer"} z-10`} onClick={() => back()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" viewBox="0 0 20 20" fill={currentIndex === 0 ? "#CBD5E1" : "#0891B2"}>
                          <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div className={`${currentIndex === (features.length - 1) ? "cursor-not-allowed" : "cursor-pointer"} z-10`} onClick={() => forward()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" viewBox="0 0 20 20" fill={currentIndex === (features.length - 1) ? "#CBD5E1" : "#0891B2"}>>
                          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div className="bg-cyan-50 hidden"></div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
})